import { ReactionEmoji } from '@/types/models';
import {
  EmojiCareIcon,
  EmojiCelebrateIcon,
  EmojiFunnyIcon,
  EmojiInsightfulIcon,
  EmojiLikeIcon,
  EmojiLoveIcon,
  ReactionHeartIcon,
} from '@/components/icons';
import { EEmojiId } from '@/types/consts';
import { FC } from 'react';

interface MyLikeReactionProps {
  myReaction: ReactionEmoji | null;
}

export const MyLikeReaction: FC<MyLikeReactionProps> = ({ myReaction }) => {
  switch (myReaction) {
    case EEmojiId.like:
      return <EmojiLikeIcon height={15} width={15} />;
    case EEmojiId.celebrate:
      return <EmojiCelebrateIcon height={15} width={15} />;
    case EEmojiId.care:
      return <EmojiCareIcon height={15} width={15} />;
    case EEmojiId.love:
      return <EmojiLoveIcon height={15} width={15} />;
    case EEmojiId.funny:
      return <EmojiFunnyIcon height={15} width={15} />;
    case EEmojiId.insightful:
      return <EmojiInsightfulIcon height={15} width={15} />;
    default:
      return <ReactionHeartIcon height={15} width={15} />;
  }
};
