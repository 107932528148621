export enum EUsers {
  getUsersList = 'users/getUsersList',
  getUsers = 'users/getListWithParams',
  getUser = 'users/getUser',
  updateUser = 'users/updateUser',
  createUser = 'users/createUser',
  uploadAvatar = 'users/uploadAvatar',
  getUserProgress = 'users/getUserProgress',
  getUserActivity = 'users/getUserActivity',
  getUserKnowledgeBase = 'users/getUserKnowledgeBase',
  deleteUserAvatar = 'users/deleteUserAvatar',
  updateBreakTimersSetting = 'users/updateBreakTimersSettings',
  deleteUser = 'users/deleteUser',
  restoreUser = 'users/restoreUser',
  getUserRewardPoints = 'users/getUserRewardPoints',
  getUserDailyStreak = 'users/getUserDailyStreak',
  getUserRewardPointsLog = 'users/getUserRewardPointsLog',
  resendLink = 'users/resendLink',
  changeRole = 'users/changeRole',
  getUserMessagesStat = 'users/getMessagesStats',
  redeemPoints = 'users/redeemPoints',
  messageReactionIncrement = 'users/messageReactionIncrement',
  messageReactionDecrement = 'users/messageReactionDecrement',
}
