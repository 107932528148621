import {
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  OutlinedInput,
  Popover as MuiPopover,
  Typography,
} from '@mui/material';
import styled from 'styled-components';
import { IChatMessagesListProps } from './Chat/ChatMessagesList';
import {
  ISendMessageBlockProps,
  ISendMessageProps,
} from '@/components/message-center/Chat/SendMessage';

interface ILastMessage {
  unread: boolean;
}

interface IMessageProps {
  myMessage: boolean;
  isColoredMessage?: boolean;
}

export const MessageCenterContainer = styled.div`
  height: calc(100vh - 85px);
  max-height: calc(100vh - 85px);
`;

export const AdminMessageCenterContainer = styled.div`
  height: calc(100vh - 88px);
  max-height: calc(100vh - 88px);
`;

export const ChatHeaderContainer = styled.div`
  padding: 10px 10px 10px 36px;
  background-color: #ebeff7;
  border: 1px solid rgba(75, 78, 117, 0.1);
`;

export const ChatTitle = styled.div`
  font-weight: 400;
  font-size: 20px;
  color: #4b4e75;
`;

export const ChatJobTitle = styled.div`
  margin-top: 8px;
  min-height: 20px;
  font-weight: 400;
  font-size: 15px;
  color: #4b4e75;
`;

export const MemberCount = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const ChatContainer = styled.div`
  background: #ffff;
  border-radius: 16px;
  height: 100%;
  width: 100%;
`;

export const ConversationListContainer = styled.div`
  width: 281px;
  border: 1px solid rgba(75, 78, 117, 0.1);
  background-color: #ebeff7;
  padding: 15px;
`;

export const AvatarContainer = styled.div`
  position: relative;
`;

export const ChatItemBox = styled(Box)<{ isactive: boolean }>`
  padding: 5px 0 5px 5px;
  border-radius: 8px;
  background-color: ${({ isactive }) => (isactive ? '#D3E6F8' : '#EBEFF7')};
`;

export const StatusDot = styled.div<{ isOnline: boolean; isSmall?: boolean }>`
  position: absolute;
  width: ${({ isSmall }) => (isSmall ? '6px' : '8px')};
  height: ${({ isSmall }) => (isSmall ? '6px' : '8px')};
  background-color: ${({ isOnline, theme }) =>
    isOnline ? theme.palette.primary.main : theme.palette.redColor.main};
  border-radius: 50%;
  right: ${({ isSmall }) => (isSmall ? '1px' : '7px')};
  bottom: 0;
`;

export const UserName = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 17px;
  overflow: hidden;
  width: 100%;
`;

export const TeamChat = styled(Box)`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 5px 0 5px 5px;
  border-radius: 8px;
  margin-bottom: 10px;
`;

export const PersonalChat = styled(Box)`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
`;

export const MessageBlock = styled(Box)`
  display: flex;
`;

export const LastMessage = styled.div<ILastMessage>`
  color: ${({ unread, theme }) =>
    unread ? theme.palette.text.primary : '#8986a7'};
  font-size: 12px;
  font-weight: 400;
  width: calc(100% - 24px);
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 18px;
  overflow: hidden;
  margin-left: 8px;
`;

export const PersonalChatInfo = styled(Box)`
  width: calc(100% - 54px);
  margin-left: 8px;
`;

export const TeamChatInfo = styled(Box)`
  width: calc(100% - 54px);
`;

export const ChatName = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-left: 9px;
`;

export const TeamMemberText = styled.div`
  margin-top: 20px;
  margin-bottom: 13px;
  font-weight: 400;
  font-size: 20px;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const SendMessageContainer = styled.div<ISendMessageProps>`
  height: ${({ plusHeight }) => `${130 + plusHeight}px`};
  position: relative;
  padding: 8px;
`;

export const SendMessageBlock = styled.div<ISendMessageBlockProps>`
  display: flex;
  padding: 7px 10px;
  background-color: ${({ theme }) => theme.palette.neutral.main};
  box-shadow: 0 24px 64px rgba(0, 5, 62, 0.15);
  border-radius: 8px;
  width: 100%;
  height: ${({ plusHeight }) => `${114 + plusHeight}px`};
`;

export const SendMessageBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 2;
`;

export const MessageField = styled(OutlinedInput)<{ plusHeight: number }>`
  width: 100%;
  height: 53px;
  padding-right: 0;

  & .MuiOutlinedInput-notchedOutline {
    display: none;
  }

  & textarea {
    position: relative;
    top: ${({ plusHeight }) => (plusHeight ? '20px' : 0)};
    cursor: auto;

    ::-webkit-scrollbar {
      width: 20px;
    }

    ::-webkit-scrollbar-track {
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      height: 30%;
      background-color: rgb(47, 214, 10, 0.6);
      border-radius: 20px;
      border: 6px solid transparent;
      background-clip: content-box;
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: rgb(47, 214, 10, 1);
      cursor: help;
    }
  }
`;

export const SendButton = styled(Button)`
  height: 44px;
  width: 110px;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const MessageIconButton = styled(IconButton)`
  margin-right: 11px;
`;

export const MessageBubbleContainer = styled.div<IMessageProps>`
  margin-bottom: 30px;
  display: flex;
  width: 100%;
  border-radius: 10px;
  padding: ${({ isColoredMessage }) => isColoredMessage && '5px'};
  background-color: ${({ isColoredMessage }) => isColoredMessage && '#fff'};
  justify-content: ${({ myMessage }) =>
    myMessage ? 'flex-end' : 'flex-start'};
`;

export const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 400px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

export const LinkAddContainer = styled.div`
  padding: 10px;
`;

export const ButtonsBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
`;

export const LinkMenuButton = styled(Button)`
  height: 44px;
  width: 110px;
  margin-left: 15px;
`;

export const SendTime = styled.div`
  font-weight: 400;
  font-size: 10px;
  color: #8f92b9;
`;

export const MessageUserName = styled.div<IMessageProps>`
  font-weight: 400;
  font-size: 12px;
  color: #5b587d;
  margin-left: ${({ myMessage }) => (myMessage ? '15px' : '6px')};
  margin-right: ${({ myMessage }) => (!myMessage ? '15px' : '6px')};
`;

export const MessageBubble = styled.div<IMessageProps>`
  padding: 14px 24px;
  background-color: ${({ myMessage, theme }) =>
    myMessage ? '#0099ff33' : '#F1F5FC'};
  border-top-right-radius: ${({ myMessage }) => (myMessage ? '0' : '30px')};
  border-top-left-radius: ${({ myMessage }) => (!myMessage ? '0' : '30px')};
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
  margin-right: ${({ myMessage }) => (myMessage ? '30px' : '5px')};
  margin-left: ${({ myMessage }) => (!myMessage ? '30px' : '5px')};
  width: 100%;
  word-break: break-word;
  position: relative;
`;

export const ChatListContainer = styled.div<IChatMessagesListProps>`
  position: relative;
  height: calc(
    100% - ${({ plusHeight }) => `${plusHeight}px`} -
      ${({ hideHeader }) => (hideHeader ? '116px' : '230px')}
      ${({ withNotification }) => (withNotification ? ' - 30px' : '')}
      ${({ withPinned }) => (withPinned ? ' - 60px' : '- 12px')}
  );
  padding-left: 24px;
  padding-right: 15px;
  padding-bottom: 15px;
  width: 100%;
`;

export const MessageList = styled.div<{ overflow: 'hidden' | 'auto' }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;

  .message-list {
    width: 100%;
    overflow-x: hidden !important;
    overflow-y: ${({ overflow }) => overflow} !important;

    ::-webkit-scrollbar {
      width: 20px;
    }

    ::-webkit-scrollbar-track {
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      height: 30%;
      background-color: rgba(170, 170, 170, 0.6);
      border-radius: 20px;
      border: 6px solid transparent;
      background-clip: content-box;
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: rgba(170, 170, 170, 1);
    }
  }
`;

export const EmojiMenuIcon = styled(IconButton)`
  margin: 10px 10px 0;

  &:hover {
    color: #4b4e75;
  }
`;

export const EmojiSelectIcon = styled.div`
  height: 34px;
  width: 34px;
  border-radius: 20px;
  border: solid 2px transparent;

  &:hover {
    border: solid 2px ${({ theme }) => theme.palette.neutral.light};
  }
`;

export const EmojiSelectPopover = styled(Popover)`
  & .MuiPaper-root {
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0 24px 64px rgba(0, 5, 62, 0.15);
    border-radius: 12px;
    background-color: ${({ theme }) => theme.palette.neutral.main};
    width: 230px;
    height: 44px;
    padding: 0 8px;
    overflow: hidden;
  }
`;

export const EmojiShowPanel = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  margin-left: 30px;
  padding: 0 20px 0 0;
`;

export const EmojiPanelIcon = styled.div`
  height: 20px;
  width: 15px;
`;

export const EmojiCount = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  white-space: pre;
  margin-left: ${({ theme }) => theme.spacing(2)};
`;

export const ContextMenuIcon = styled(IconButton)`
  margin-top: 10px;

  &:hover {
    color: #4b4e75;
  }
`;

export const ContextMenu = styled(Menu)`
  & .MuiPaper-root {
    box-shadow: 0 24px 64px rgba(0, 5, 62, 0.15);
    border-radius: 12px;
    background-color: ${({ theme }) => theme.palette.neutral.main};
    width: 181px;
    padding: 17px 10px 20px 0;
  }
`;

export const ContextMenuItem = styled(MenuItem)`
  color: ${({ theme }) => theme.palette.text.secondary};
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: 500;
  &:active,
  &:hover {
    color: ${({ theme }) => theme.palette.text.primary};
    background: #2c294c19;
    border-left: 2px solid ${({ theme }) => theme.palette.text.secondary};
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }
`;

export const MenuItemIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 22px;
  margin-right: ${({ theme }) => theme.spacing(3)};
`;

export const SendImageWrapper = styled(Box)`
  position: relative;
  height: 97px;
`;

export const ImagePreview = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const ImagePreviewCover = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 3px;
`;

export const CloseIconWrapper = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  background: aliceblue;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  top: -5px;
  right: -5px;
  cursor: pointer;
  & svg {
    width: 8px;
    height: 8px;
  }
`;

export const InnerScrollContainer = styled.div<{ isAdmin?: boolean }>`
  height: calc(100vh - ${({ isAdmin }) => (isAdmin ? '257px' : '171px')});
`;

export const AdminContainer = styled(Container)`
  border: 1px solid #e2e8edbf;
  border-radius: 16px;
  padding: 0;
`;

export const UnreadBadge = styled(Box)`
  width: 18px;
  height: 18px;
  background: #5999f9;
  border-radius: 50%;
  color: ${({ theme }) => theme.palette.neutral.main};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  font-size: 12px;
`;

export const AdminOfflineBlock = styled(Box)`
  background: #ebeff7;
  border-radius: 8px 8px 0px 0px;
  padding: 8px 12px 3px;
  font-size: 10px;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const PinnedMessageContainer = styled(Box)`
  cursor: pointer;
  background: #f2ffef;
  border-radius: 0px 0px 8px 8px;
  padding: 8px 30px;
  font-size: 14px;
  line-height: 32px;
  color: #21b101;
`;

export const Spliter = styled.div`
  border: 2px solid #2fd60a;
  height: 30px;
  border-radius: 2px;
  width: 0;
  margin-right: 7px;
`;
