import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import useAuth from '@/hooks/useAuth';
import useNotifications from '@/hooks/useNotification';
import {
  getMembers,
  updateMessages,
  updateMessagesRead,
  updatePinnedMessage,
  updateUserStatus,
} from '@/redux/actions/chat';
import { getMyCompany } from '@/redux/actions/user';
import { updateDeletedMessage, updateMessage } from '@/redux/reducers/chat';
import { chatUserSelector } from '@/redux/selectors/chatSelector';
import {
  EConversationType,
  EMessageCategory,
  EMessageType,
  EUserRole,
} from '@/types/consts';

import { IUser } from '@/types/models';
import { idToUid } from '@/utils/chatHelpers';
import { CometChat } from '@cometchat-pro/chat';
import { format } from 'date-fns';
import React, { FC, ReactNode, useEffect, useState } from 'react';

interface IChatProviderProps {
  children: ReactNode;
}

const ChatProvider: FC<IChatProviderProps> = ({ children }) => {
  const { user } = useAuth();
  const chatUser = useAppSelector(chatUserSelector);
  const dispatch = useAppDispatch();
  const [companyTeam, setCompanyTeam] = useState<IUser[] | null>(null);
  const [messagesListener, setMessagesListener] = useState<string | null>(null);
  const [usersListener, setUsersListener] = useState<string | null>(null);
  const { notify } = useNotifications();

  useEffect(() => {
    if (
      user?.role.name === EUserRole.TEAMLEAD ||
      user?.role.name === EUserRole.USER
    ) {
      dispatch(getMyCompany(user.companies[0].id))
        .unwrap()
        .then(({ users, rwAdmin }) => {
          setCompanyTeam(
            rwAdmin
              ? [
                  ...users.filter(
                    ({ id, isConfirm }) => isConfirm && id !== user?.id
                  ),
                  rwAdmin,
                ]
              : users.filter(({ id }) => id !== user?.id)
          );
        });
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (companyTeam?.length && !!chatUser?.uid) {
      dispatch(
        getMembers(
          companyTeam.filter((item) => !!item).map(({ id }) => idToUid(id))
        )
      );
    }
  }, [companyTeam, chatUser?.uid, user?.companies[0]?.rwAdminId]);

  useEffect(() => {
    if (messagesListener) {
      CometChat.addMessageListener(
        messagesListener,
        new CometChat.MessageListener({
          onTextMessageReceived: (textMessage: CometChat.TextMessage) => {
            if (
              textMessage &&
              textMessage.getCategory().toUpperCase() !==
                EMessageCategory.ACTION
            ) {
              dispatch(updateMessages(textMessage));
              if (
                user?.id &&
                textMessage.getSender().getUid() !== idToUid(user.id) &&
                user?.newMessagesSounds
              ) {
                notify(
                  `${textMessage.getSender().getName()}: ${
                    textMessage.getData().text
                  }`,
                  true,
                  require('@/audio/new_message.mp3'),
                  true,
                  'New message in RemoteWorx'
                );
              }
            }
          },
          onMediaMessageReceived: (mediaMessage: CometChat.MediaMessage) => {
            if (
              mediaMessage &&
              mediaMessage.getCategory().toUpperCase() !==
                EMessageCategory.ACTION
            ) {
              dispatch(updateMessages(mediaMessage));
              if (user?.newMessagesSounds) {
                notify(
                  `${mediaMessage.getSender().getName()}: 🖼 Sent picture`,
                  true,
                  require('@/audio/new_message.mp3'),
                  true,
                  'New message in RemoteWorx'
                );
              }
            }
          },
          onMessageDeleted: (message: CometChat.BaseMessage) => {
            dispatch(updateDeletedMessage(`${message.getId()}`));
          },
          // onMessagesRead: (messageReceipt: CometChat.MessageReceipt) => {
          //   CometChat.getMessageDetails(messageReceipt.getMessageId()).then(
          //     (messageBase) => {
          //       console.log('socket', messageBase);
          //       const message = messageBase as
          //         | CometChat.MediaMessage
          //         | CometChat.TextMessage;
          //       dispatch(
          //         updateMessagesRead({
          //           consversationId: message.getConversationId(),
          //           data: JSON.parse(JSON.stringify(message.getData())),
          //           id: `${message.getId()}`,
          //           receiver: message.getReceiverId(),
          //           sentAt: message.getSentAt(),
          //           receiverType:
          //             message.getReceiverType() as EConversationType,
          //           type: message.getType(),
          //           sender: message.getSender().getUid(),
          //           isUnread: !message.getReadAt(),
          //         })
          //       );
          //     }
          //   );
          // },
          onMessageEdited: (message: CometChat.BaseMessage) => {
            dispatch(updateMessage(message));
            dispatch(
              updatePinnedMessage({
                tags: (
                  message as CometChat.TextMessage | CometChat.MediaMessage
                ).getTags(),
                id: message.getId().toString(),
                type: message.getType() as EMessageType,
                chatId: message.getReceiverId(),
                data: JSON.parse(
                  JSON.stringify(
                    (
                      message as CometChat.TextMessage | CometChat.MediaMessage
                    ).getData()
                  )
                ),
              })
            );
          },
        })
      );
    }
  }, [messagesListener, user]);

  useEffect(() => {
    if (chatUser) {
      setMessagesListener((listener) => {
        !!listener && resetMessageListener(listener);
        return user?.id
          ? `messages-${user?.id}-${format(new Date(), 'T')}`
          : null;
      });
      setUsersListener((listener) => {
        !!listener && resetUsersListener(listener);
        return user?.id ? `users-${user?.id}-${format(new Date(), 'T')}` : null;
      });
    }
  }, [user?.id, chatUser]);

  const resetMessageListener = (listener: string) => {
    CometChat.removeMessageListener(listener);
  };

  const resetUsersListener = (listener: string) => {
    CometChat.removeUserListener(listener);
  };

  useEffect(() => {
    if (usersListener) {
      CometChat.addUserListener(
        usersListener,
        new CometChat.UserListener({
          onUserOnline: (onlineUser: CometChat.User) => {
            dispatch(updateUserStatus(onlineUser));
          },
          onUserOffline: (offlineUser: CometChat.User) => {
            dispatch(updateUserStatus(offlineUser));
          },
        })
      );
    }
  }, [usersListener]);

  return <>{children}</>;
};

export default ChatProvider;
