import {
  EmojiCareIcon,
  EmojiCelebrateIcon,
  EmojiFunnyIcon,
  EmojiInsightfulIcon,
  EmojiLikeIcon,
  EmojiLoveIcon,
} from '@/components/icons';
import { EEmojiId, EEmojiName } from '@/types/consts';

export const allEmoji = [
  {
    id: EEmojiId.like,
    name: EEmojiName.like,
    EmojiIcon: EmojiLikeIcon,
  },
  {
    id: EEmojiId.celebrate,
    name: EEmojiName.celebrate,
    EmojiIcon: EmojiCelebrateIcon,
  },
  {
    id: EEmojiId.care,
    name: EEmojiName.care,
    EmojiIcon: EmojiCareIcon,
  },
  {
    id: EEmojiId.love,
    name: EEmojiName.love,
    EmojiIcon: EmojiLoveIcon,
  },
  {
    id: EEmojiId.funny,
    name: EEmojiName.funny,
    EmojiIcon: EmojiFunnyIcon,
  },
  {
    id: EEmojiId.insightful,
    name: EEmojiName.insightful,
    EmojiIcon: EmojiInsightfulIcon,
  },
];
