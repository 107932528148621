import { IMessage, ReactionEmoji, Reactions } from '@/types/models';

export const uidToId = (uid?: string) => {
  if (!uid) {
    return null;
  }
  return +uid.split(/users-/g)[1];
};

export const idToUid = (id: number) => `users-${id}`;

export const getReactionsFromMessage = (
  message: IMessage
): Reactions | null => {
  const metadata = message.data.metadata;
  if (metadata != null) {
    const injectedObject = metadata['@injected'];
    if (injectedObject != null && injectedObject.hasOwnProperty('extensions')) {
      const extensionsObject = injectedObject['extensions'];
      if (
        extensionsObject != null &&
        extensionsObject.hasOwnProperty('reactions')
      ) {
        return extensionsObject['reactions'];
      }
    }
  }
  return null;
};

export const getCountReactions = (reactions: Reactions): number | null => {
  let totalUsers = 0;

  for (const reaction in reactions) {
    const users = reactions[reaction as ReactionEmoji];
    if (users) {
      totalUsers += Object.keys(users).length;
    }
  }

  return totalUsers ? totalUsers : null;
};

export const getMyReaction = (
  reactions: Reactions,
  userId: number
): ReactionEmoji | null => {
  for (const reaction in reactions) {
    const users = reactions[reaction as ReactionEmoji];
    if (users && users[idToUid(userId)]) {
      return reaction as ReactionEmoji;
    }
  }
  return null;
};
