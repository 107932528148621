export enum EChat {
  loginUser = 'chat/loginUser',
  getChatsMembers = 'chat/getMembers',
  updateUserStatus = 'chat/updateUserStatus',
  getChatUreadMessages = 'chat/getAllUnreadMessages',
  getUserConversation = 'chat/getUserConversation',
  getMessages = 'chat/getMessages',
  getPrevMessages = 'chat/getPrevMessages',
  sendMessage = 'chat/sendMessage',
  sendImage = 'chat/sendImage',
  deleteMessage = 'chat/deleteMessage',
  updateMessages = 'chat/updateMessages',
  readMessage = 'chat/readMessage',
  updateReadMessage = 'chat/updateReadMessage',
  logout = 'chat/logout',
  pinMessage = 'chat/pinMessage',
  receivePinnedMessage = 'chat/getPinnedMessage',
  unpinMessage = 'chat/unpinMessage',
  updatePinnedMessage = 'chat/updatePinnedMessage',
  addReactionToMessage = 'chat/addReactionToMessage',
  removeReactionFromMessage = 'chat/removeReactionFromMessage',
}
