import {
  CopyIcon,
  DeleteIcon,
  LinkIcon,
  PinIcon,
  PinnedMessageIcon,
} from '@/components/icons';
import { UserContextMenu } from '@/components/team-admin/components';
import { AvatarType, CustomAvatar } from '@/components/UI/CustomAvatar';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import useAuth from '@/hooks/useAuth';
import {
  addOrRemoveReactionToMessage,
  deleteMessage,
  pinMessage,
  unpinMessage,
} from '@/redux/actions/chat';
import { getMyTeamMember } from '@/redux/selectors/userSelectors';
import { EEmojiId, EMessageType, EMOJI_REGEX, EUserRole } from '@/types/consts';
import {
  IImageData,
  IMessage,
  IMessageData,
  IUser,
  Reactions,
} from '@/types/models';
import {
  getCountReactions,
  getMyReaction,
  getReactionsFromMessage,
  uidToId,
} from '@/utils/chatHelpers';
import { getUserAvatarLetter } from '@/utils/getAvatarLeters';
import { urlify } from '@/utils/textHelpers';
import { Box, Tooltip } from '@mui/material';
import { format, fromUnixTime } from 'date-fns';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  ContextMenu,
  ContextMenuIcon,
  ContextMenuItem,
  EmojiCount,
  EmojiMenuIcon,
  EmojiPanelIcon,
  EmojiSelectIcon,
  EmojiSelectPopover,
  EmojiShowPanel,
  ImagePreview,
  MenuItemIcon,
  MessageBubble,
  MessageBubbleContainer,
  MessageUserName,
  SendTime,
} from '../components';
import { pinnedMessageSelector } from '@/redux/selectors/chatSelector';
import { unpinDeletedMessage } from '@/redux/reducers/chat';
import { allEmoji } from '@/components/message-center/constants';
import { MyLikeReaction } from '@/components/message-center/Chat/MyLikeReaction';
import {
  messageReactionDecrement,
  messageReactionIncrement,
} from '@/redux/actions/users';

interface IMessageBubbleProps {
  message: IMessage;
  isMyMessage: boolean;
  isRwAdminMessage: boolean;
  key: string;
  isGroupChat: boolean;
  setIsScrolled?: React.Dispatch<React.SetStateAction<boolean>>;
}

const Message: FC<IMessageBubbleProps> = ({
  message,
  isMyMessage,
  isRwAdminMessage,
  isGroupChat,
  setIsScrolled,
}) => {
  const dispatch = useAppDispatch();
  const me = useAuth().user as IUser;
  const teamMember = useAppSelector(getMyTeamMember(uidToId(message.sender)));
  const pinnedMessage = useAppSelector(pinnedMessageSelector);
  const [anchorMenu, setAnchorMenu] = useState<any>(null);
  const [anchorEmojiPanel, setAnchorEmojiPanel] = useState<any>(null);
  const [reactions, setReactions] = useState<Reactions | null>(null);

  useEffect(() => {
    setReactions(getReactionsFromMessage(message));
  }, [message]);

  const showReactions = useMemo(
    () => (reactions ? allEmoji.filter(({ id }) => id in reactions) : null),
    [reactions]
  );

  const countReactions = useMemo(
    () => (reactions ? getCountReactions(reactions) : null),
    [reactions]
  );

  const myReaction = useMemo(
    () => (reactions ? getMyReaction(reactions, me.id) : null),
    [reactions]
  );

  const linkForImage =
    message.type === EMessageType.IMAGE &&
    'msgLink' in message.data &&
    message.data.msgLink;

  const user = useMemo(() => {
    if (isMyMessage) {
      return me;
    }

    return teamMember;
  }, [isMyMessage, isRwAdminMessage, teamMember, me]);

  const closeMenu = () => {
    setAnchorMenu(null);
    setIsScrolled && setIsScrolled(true);
  };

  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeEmojiSelectPopover = () => {
    setAnchorEmojiPanel(null);
    setIsScrolled && setIsScrolled(true);
  };

  const toggleEmojiSelectPopover = (event: React.SyntheticEvent) => {
    setAnchorEmojiPanel(event.currentTarget);
  };

  const onClickReaction = (event: React.SyntheticEvent) => {
    if (myReaction !== null) {
      dispatch(
        addOrRemoveReactionToMessage({
          messageId: message.id,
          emoji: EEmojiId[myReaction],
        })
      );
      dispatch(messageReactionDecrement());
    } else {
      setAnchorEmojiPanel(event.currentTarget);
    }
    setIsScrolled && setIsScrolled(true);
  };

  const handleSelectEmoji = (emojiId: EEmojiId) => {
    dispatch(
      addOrRemoveReactionToMessage({ messageId: message.id, emoji: emojiId })
    );
    dispatch(messageReactionIncrement());
    closeEmojiSelectPopover();
  };

  const copyMessage = useCallback(() => {
    if (message.type === EMessageType.TEXT) {
      navigator.clipboard.writeText((message.data as IMessageData).text);
      closeMenu();
    }
  }, [message]);

  const deleteMsg = useCallback(() => {
    dispatch(deleteMessage(message.id));
    if (pinnedMessage?.id === message.id) {
      dispatch(unpinDeletedMessage());
    }
    closeMenu();
  }, [message]);

  const pinMsg = useCallback(() => {
    if (pinnedMessage?.chatId === message.receiver) {
      dispatch(unpinMessage({ messageId: +pinnedMessage.id }))
        .unwrap()
        .then(() => {
          dispatch(pinMessage({ messageId: +message.id }));
        });
    } else {
      dispatch(pinMessage({ messageId: +message.id }));
    }
    closeMenu();
  }, [message, pinnedMessage]);

  const unpinMsg = useCallback(() => {
    dispatch(unpinMessage({ messageId: +message.id }));
    closeMenu();
  }, [message]);

  const isOnlyEmojiMessage = useMemo(() => {
    const text = (message.data as IMessageData).text;
    return !!text?.match(EMOJI_REGEX);
  }, [message.data]);

  const isPinOptionAvailable = useMemo(
    () => me.role.name === EUserRole.RW_ADMIN && isMyMessage && isGroupChat,
    [me?.role?.name, isMyMessage, isGroupChat]
  );

  const isPinnedMsg = pinnedMessage && pinnedMessage?.id === message.id;

  return (
    <MessageBubbleContainer
      myMessage={isMyMessage}
      id={`message-${message.id}`}
      isColoredMessage={isPinnedMsg}
    >
      <Box display="flex" flexDirection="column" maxWidth="80%">
        <Box
          display="flex"
          flexDirection={isMyMessage ? 'row' : 'row-reverse'}
          justifyContent="flex-end"
        >
          <SendTime>
            {format(fromUnixTime(message.sentAt), 'MM/dd/yyyy hh:mm a')}
          </SendTime>
          <Box
            display="flex"
            flexDirection={isMyMessage ? 'row' : 'row-reverse'}
          >
            <MessageUserName myMessage={isMyMessage}>
              {user?.firstName
                ? `${user?.firstName} ${user.lastName}`
                : user?.email}
            </MessageUserName>
            <CustomAvatar
              diameter={24}
              avatartype={AvatarType.USER}
              sx={{
                fontSize: 16,
              }}
              alt="Remy Sharp"
              src={user?.profileImage || undefined}
            >
              {!user
                ? ''
                : getUserAvatarLetter({
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                  })}
            </CustomAvatar>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection={isMyMessage ? 'row' : 'row-reverse'}
          justifyContent="flex-end"
          width="100%"
        >
          {!isMyMessage && (
            <>
              <EmojiMenuIcon
                disableRipple
                disableFocusRipple
                onClick={onClickReaction}
                aria-haspopup="true"
              >
                <MyLikeReaction myReaction={myReaction} />
              </EmojiMenuIcon>
              {anchorEmojiPanel && (
                <EmojiSelectPopover
                  open={Boolean(toggleEmojiSelectPopover)}
                  onClose={closeEmojiSelectPopover}
                  anchorEl={anchorEmojiPanel}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  {allEmoji.map(({ EmojiIcon, id, name }) => (
                    <Tooltip title={name} placement="top">
                      <EmojiSelectIcon onClick={() => handleSelectEmoji(id)}>
                        <EmojiIcon height={30} width={30} />
                      </EmojiSelectIcon>
                    </Tooltip>
                  ))}
                </EmojiSelectPopover>
              )}
            </>
          )}

          {(message.type === EMessageType.TEXT || isMyMessage) && (
            <ContextMenuIcon
              disableRipple
              disableFocusRipple
              aria-owns={
                Boolean(anchorMenu) ? `menu-message-${message.id}` : undefined
              }
              aria-haspopup="true"
              onClick={toggleMenu}
            >
              <UserContextMenu />
            </ContextMenuIcon>
          )}
          <ContextMenu
            id={`menu-message-${message.id}`}
            anchorEl={anchorMenu}
            open={Boolean(anchorMenu)}
            onClose={closeMenu}
          >
            {message.type === EMessageType.TEXT && (
              <ContextMenuItem onClick={() => copyMessage()}>
                <MenuItemIcon>
                  <CopyIcon />
                </MenuItemIcon>
                Copy
              </ContextMenuItem>
            )}
            {isMyMessage && (
              <ContextMenuItem onClick={() => deleteMsg()}>
                <MenuItemIcon>
                  <DeleteIcon />
                </MenuItemIcon>
                Delete
              </ContextMenuItem>
            )}
            {isPinOptionAvailable && (
              <ContextMenuItem
                onClick={() =>
                  pinnedMessage && pinnedMessage?.id === message.id
                    ? unpinMsg()
                    : pinMsg()
                }
              >
                {pinnedMessage && pinnedMessage?.id === message.id ? (
                  <>
                    <MenuItemIcon>
                      <PinIcon />
                    </MenuItemIcon>
                    Unpin
                  </>
                ) : (
                  <>
                    <MenuItemIcon>
                      <PinIcon />
                    </MenuItemIcon>
                    Pin
                  </>
                )}
              </ContextMenuItem>
            )}
          </ContextMenu>
          <MessageBubble myMessage={isMyMessage}>
            {message.type === EMessageType.TEXT ? (
              <Box
                fontSize={isOnlyEmojiMessage ? '32px' : '14px'}
                dangerouslySetInnerHTML={{
                  __html: urlify((message.data as IMessageData).text),
                }}
              ></Box>
            ) : (
              <>
                <Box minHeight={200} height="20vh">
                  {linkForImage ? (
                    <a href={linkForImage} target="_blank" rel="noreferrer">
                      <ImagePreview src={(message.data as IImageData).url} />
                    </a>
                  ) : (
                    <ImagePreview src={(message.data as IImageData).url} />
                  )}
                </Box>
                {message.data.text && (
                  <Box mt={1} maxWidth={450}>
                    {message.data.text}
                  </Box>
                )}
              </>
            )}
            {linkForImage ? (
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                {linkForImage && (
                  <a href={linkForImage} target="_blank" rel="noreferrer">
                    <Box display="flex" alignItems="center">
                      <LinkIcon stroke="#27A80A" />
                    </Box>
                  </a>
                )}
                {isPinnedMsg && (
                  <Box ml={1}>
                    <PinnedMessageIcon />
                  </Box>
                )}
              </Box>
            ) : (
              <>
                {isPinnedMsg && (
                  <Box position="absolute" bottom="9px" right="9px">
                    <PinnedMessageIcon />
                  </Box>
                )}
              </>
            )}
          </MessageBubble>
        </Box>

        <EmojiShowPanel>
          {!!countReactions && (
            <>
              <Box display="flex">
                {showReactions?.map(({ EmojiIcon, name }) => (
                  <Tooltip title={name} placement="top">
                    <EmojiPanelIcon>
                      <EmojiIcon height={20} width={20} />
                    </EmojiPanelIcon>
                  </Tooltip>
                ))}
              </Box>
              <EmojiCount>{countReactions}</EmojiCount>
            </>
          )}
        </EmojiShowPanel>
      </Box>
    </MessageBubbleContainer>
  );
};

export default Message;
