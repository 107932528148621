export enum ELocalStoreKeys {
  ACCESS_TOKEN = 'accessToken',
  REFRESH_TOKEN = 'refreshToken',
  CHAT_TOKEN = 'chatToken',
  PINNED_ID = 'pinnedId',
}

export enum EServerStatusCode {
  EXPIRED_TOKEN = 401,
  LOGIN_ERROR = 401,
}

export enum EUserRole {
  SUPER_ADMIN = 'super_admin',
  RW_ADMIN = 'rw_admin',
  TEAMLEAD = 'team_admin',
  USER = 'user',
}

export const roles = {
  [EUserRole.USER]: '1',
  [EUserRole.RW_ADMIN]: '2',
  [EUserRole.TEAMLEAD]: '3',
};

export const rolesListName = [
  {
    name: 'rw_admin',
    view_name: 'RW Admin',
  },
  {
    name: 'team_admin',
    view_name: 'Team Admin',
  },
  {
    name: 'user',
    view_name: 'User',
  },
];

export const changeRolesListName = [
  {
    name: 'team_admin',
    view_name: 'Team Admin',
  },
  {
    name: 'user',
    view_name: 'User',
  },
];

export enum EPeriod {
  WEEK = 'Week',
  MONTH = 'Month',
  DAY = 'Day',
}

export enum EUserProfileRoutes {
  PROFILE = 'info',
  ACTIVITY = 'activity',
  POINTS = 'points',
  COMPANY = 'company',
  POINTS_MANAGEMENT = 'points-management',
}

export enum EActivityLevel {
  STATISTIC = 'statistic',
  DYNAMIC = 'dynamic',
}

export enum ELimits {
  ACTIVITY = 10,
  COMPANY = 50,
  USER = 50,
  GROUP_CHAT = 10,
  MESSAGES = 50,
}

export enum ECompanyActivitySelect {
  TEAM = 'team',
  INDIVIDUAL = 'individual',
}

export enum EChatStatus {
  ONLINE = 'online',
  OFFLINE = 'offline',
}

export enum EConversationType {
  GROUP = 'group',
  PERSONAL = 'user',
}

export enum EMessageCategory {
  MESSAGE = 'message',
  CUSTOM = 'custom',
  ACTION = 'action',
}

export enum EMessageType {
  TEXT = 'text',
  IMAGE = 'image',
  FILE = 'file',
  AUDIO = 'audio',
  VIDEO = 'video',
}

export enum EAdminHeaderKeys {
  ACCOUNTS = 'acc',
  MESSAGE = 'message',
  KNOWLEDGE = 'kb',
}

export enum EMessageTags {
  PINNED = 'pinned',
  UNPINNED = 'unpinned',
}

export enum EEmojiId {
  like = 'like',
  celebrate = 'celebrate',
  care = 'care',
  love = 'love',
  funny = 'funny',
  insightful = 'insightful',
}

export enum EEmojiName {
  like = 'Like',
  celebrate = 'Celebrate',
  care = 'Care',
  love = 'Love',
  funny = 'Funny',
  insightful = 'Insightful',
}

export const PASSWORD_REGEX =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z\W_]{8,}$/;
export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/;
export const EMOJI_REGEX = /^[\p{Extended_Pictographic}\u200D\s]+$/gu;
export const MAX_AVATAR_IMAGE_SIZE = 5242880; // 5 Mb

export const DEFAULT_TOAST_TIME = 10000; // 10 sec
