import {
  EChatStatus,
  EConversationType,
  ELimits,
  ELocalStoreKeys,
  EMessageTags,
  EMessageType,
} from '@/types/consts';
import {
  IConversation,
  ICurrentChat,
  ILikedMessageParams,
  IMessage,
  IPinnedMessage,
  IPinnedMessageParams,
  ISendImage,
  ISendMessage,
  IUpdatedMessage,
  IUserChat,
} from '@/types/models';
import { CometChat } from '@cometchat-pro/chat';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { EChat } from '../constants';
import cometchatInstance from '../middlewares/cometchat';
import { resetChatState, setPinnedMessage } from '../reducers/chat';
import { RootState } from '../store';

const AuthKey = process.env.REACT_APP_COMETCHAT_AUTH;

const loginChatUser = createAsyncThunk(
  EChat.loginUser,
  async (uid: string, thunkAPI) => {
    try {
      const loggedUser = await CometChat.getLoggedinUser();
      if (loggedUser) {
        await thunkAPI.dispatch(logoutChatUser());
      }
      const user = await CometChat.login(uid, AuthKey as string);
      localStorage.setItem(ELocalStoreKeys.CHAT_TOKEN, user.getAuthToken());
      return {
        uid: user.getUid(),
        name: user.getName(),
        avatar: user.getAvatar(),
        status: user.getStatus() as EChatStatus,
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const getMembers = createAsyncThunk(
  EChat.getChatsMembers,
  async (uids: string[], thunkAPI) => {
    const requestLimit = 100;
    try {
      const listUids = uids.filter((item) => !!item);
      const uidsPacks = [];
      for (
        let startIndex = 0;
        startIndex < listUids.length;
        startIndex += requestLimit
      ) {
        uidsPacks.push(listUids.slice(startIndex, startIndex + requestLimit));
      }
      const resArr: any[] = await Promise.all(
        uidsPacks.map(async (pack) => {
          const usersRequest: CometChat.UsersRequest =
            new CometChat.UsersRequestBuilder()
              .setLimit(requestLimit)
              .setUIDs(pack)
              .build();

          return await usersRequest.fetchNext();
        })
      );
      const members: CometChat.User[] | [] = [].concat(...resArr);

      return [
        ...members.map((member) => ({
          uid: member.getUid(),
          name: member.getName(),
          avatar: member.getAvatar(),
          status: member.getStatus(),
        })),
      ] as IUserChat[];
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const updateUserStatus = createAsyncThunk(
  EChat.updateUserStatus,
  async (user: CometChat.User, thunkAPI) => {
    try {
      return {
        uid: user.getUid(),
        name: user.getName(),
        avatar: user.getAvatar(),
        status: user.getStatus(),
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const getUserConversation = createAsyncThunk(
  EChat.getUserConversation,
  async (uid: string, thunkAPI) => {
    try {
      const conversations = await cometchatInstance.get(
        `users/${uid}/conversations`,
        { headers: { onBehalfOf: uid } }
      );
      return JSON.parse(JSON.stringify(conversations.data.data));
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const getUnreadMessagesByChat = createAsyncThunk(
  EChat.getChatUreadMessages,
  async (currenChat: ICurrentChat, thunkAPI) => {
    try {
      const messagesRequest = currenChat.isGroup
        ? new CometChat.MessagesRequestBuilder().setGUID(
            currenChat.chatId as string
          )
        : new CometChat.MessagesRequestBuilder().setUID(
            currenChat.chatId as string
          );

      const messages = await messagesRequest
        .setLimit(ELimits.MESSAGES)
        .hideDeletedMessages(false)
        .setUnread(true)
        .build()
        .fetchPrevious();

      return [
        ...messages.map(
          (item) =>
            ({
              ...JSON.parse(JSON.stringify(item)),
              sender: item.getSender().getUid(),
              receiver: item.getReceiverId(),
              isUnread: true,
              deletedAt: item.getDeletedAt(),
            } as IMessage)
        ),
      ];
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const getMessages = createAsyncThunk(
  EChat.getMessages,
  async (currenChat: ICurrentChat, thunkAPI) => {
    try {
      const messagesRequest = currenChat.isGroup
        ? new CometChat.MessagesRequestBuilder().setGUID(
            currenChat.chatId as string
          )
        : new CometChat.MessagesRequestBuilder().setUID(
            currenChat.chatId as string
          );

      const messages = await messagesRequest
        .setLimit(ELimits.MESSAGES)
        .setCategories(['message', 'custom'])
        .hideDeletedMessages(true)
        .build()
        .fetchPrevious();

      return [
        ...messages.map(
          (item) =>
            ({
              ...JSON.parse(JSON.stringify(item)),
              sender: item.getSender().getUid(),
              receiver: item.getReceiverId(),
              isUnread: !item.getReadAt(),
              deletedAt: item.getDeletedAt(),
            } as IMessage)
        ),
      ];
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const getPrevMessages = createAsyncThunk(
  EChat.getPrevMessages,
  async (currenChat: ICurrentChat, thunkAPI) => {
    try {
      const { chat } = thunkAPI.getState() as RootState;
      const messageId = (chat.messages as IMessage[])[0].id;
      const messagesRequest = currenChat.isGroup
        ? new CometChat.MessagesRequestBuilder().setGUID(
            currenChat.chatId as string
          )
        : new CometChat.MessagesRequestBuilder().setUID(
            currenChat.chatId as string
          );

      const messages = await messagesRequest
        .setLimit(ELimits.MESSAGES)
        .setMessageId(+messageId)
        .setCategories(['message', 'custom'])
        .hideDeletedMessages(true)
        .build()
        .fetchPrevious();

      return [
        ...messages.map(
          (item) =>
            ({
              ...JSON.parse(JSON.stringify(item)),
              sender: item.getSender().getUid(),
              receiver: item.getReceiverId(),
              isUnread: !item.getReadAt(),
              deletedAt: item.getDeletedAt(),
            } as IMessage)
        ),
      ];
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const sendMessage = createAsyncThunk(
  EChat.sendMessage,
  async (message: ISendMessage, thunkAPI) => {
    try {
      const { chat } = thunkAPI.getState() as RootState;
      const { uid } = chat.userChat as IUserChat;
      const conversations = chat.conversations as IConversation[] | null;
      const messages = await cometchatInstance.post(`messages`, message, {
        headers: {
          onBehalfOf: uid,
        },
      });
      if (
        conversations &&
        !conversations.some(
          ({ conversationId }) =>
            conversationId === messages.data.data.consversationId
        )
      ) {
        await thunkAPI.dispatch(getUserConversation(uid)).unwrap();
      }
      return messages.data.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const sendImage = createAsyncThunk(
  EChat.sendImage,
  async (
    {
      receiverId,
      messageType,
      file,
      receiverType,
      msgLink,
      caption,
    }: ISendImage,
    thunkAPI
  ) => {
    try {
      const { chat } = thunkAPI.getState() as RootState;
      const { uid } = chat.userChat as IUserChat;
      const conversations = chat.conversations as IConversation[] | null;
      const mediaMessage: CometChat.MediaMessage = new CometChat.MediaMessage(
        receiverId,
        file,
        messageType,
        receiverType
      );

      if (msgLink) {
        mediaMessage.setData({ msgLink });
      }

      if (caption) {
        mediaMessage.setCaption(caption);
      }

      const message = (await CometChat.sendMediaMessage(
        mediaMessage
      )) as CometChat.MediaMessage;

      if (
        conversations &&
        !conversations.some(
          ({ conversationId }) => conversationId === message.getConversationId()
        )
      ) {
        await thunkAPI.dispatch(getUserConversation(uid)).unwrap();
      }

      return {
        conversationId: message.getConversationId(),
        data: JSON.parse(JSON.stringify(message.getData())),
        id: `${message.getId()}`,
        receiver: message.getReceiverId(),
        sentAt: message.getSentAt(),
        receiverType: message.getReceiverType() as EConversationType,
        type: message.getType(),
        sender: message.getSender().getUid(),
        isUnread: !message.getReadAt(),
        deletedAt: message.getDeletedAt(),
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const deleteMessage = createAsyncThunk(
  EChat.deleteMessage,
  async (messageId: string, thunkAPI) => {
    try {
      const { chat } = thunkAPI.getState() as RootState;
      const { uid } = chat.userChat as IUserChat;
      await cometchatInstance.delete(`messages/${messageId}`, {
        headers: {
          onBehalfOf: uid,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      return messageId;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const updateMessages = createAsyncThunk(
  EChat.updateMessages,
  async (message: CometChat.TextMessage | CometChat.MediaMessage, thunkAPI) => {
    try {
      const { chat } = thunkAPI.getState() as RootState;
      const { uid } = chat.userChat as IUserChat;
      const conversations = chat.conversations as IConversation[] | null;
      if (
        conversations &&
        !conversations.some(
          ({ conversationId }) => conversationId === message.getConversationId()
        )
      ) {
        await thunkAPI.dispatch(getUserConversation(uid)).unwrap();
      }
      return {
        conversationId: message.getConversationId(),
        data: JSON.parse(JSON.stringify(message.getData())),
        id: `${message.getId()}`,
        receiver: message.getReceiverId(),
        sentAt: message.getSentAt(),
        receiverType: message.getReceiverType() as EConversationType,
        type: message.getType(),
        sender: message.getSender().getUid(),
        isUnread: !message.getReadAt(),
        deletedAt: message.getDeletedAt(),
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const readMessage = createAsyncThunk(
  EChat.readMessage,
  async (message: IMessage, thunkAPI) => {
    try {
      const { chat } = thunkAPI.getState() as RootState;
      const { chatId, isGroup } = chat.currentChat as ICurrentChat;
      const { uid } = chat.userChat as IUserChat;

      const link = isGroup
        ? `/groups/${chatId}/conversation/read`
        : `/users/${chatId}/conversation/read`;
      await cometchatInstance.post(
        link,
        {},
        {
          headers: {
            onBehalfOf: uid,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
      );
      await thunkAPI.dispatch(updateMessagesRead(message));
      return true;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const updateMessagesRead = createAsyncThunk(
  EChat.updateReadMessage,
  async (message: IMessage, thunkAPI) => {
    try {
      const countOfUnreadMessages =
        message.receiverType === EConversationType.PERSONAL
          ? await CometChat.getUnreadMessageCountForUser(message.sender)
          : await CometChat.getUnreadMessageCountForGroup(message.receiver);
      const unreadCount = countOfUnreadMessages.hasOwnProperty(
        message.receiverType === EConversationType.PERSONAL
          ? message.sender
          : message.receiver
      )
        ? (countOfUnreadMessages as { [key: string]: number })[
            message.receiverType === EConversationType.PERSONAL
              ? message.sender
              : message.receiver
          ]
        : 0;

      return {
        conversationId: message.conversationId,
        unreadCount,
        message: JSON.parse(JSON.stringify(message)),
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const logoutChatUser = createAsyncThunk(EChat.logout, async (_, thunkAPI) => {
  try {
    const { chat } = thunkAPI.getState() as RootState;
    if (!!chat.userChat) {
      await CometChat.logout();
    }
    localStorage.removeItem(ELocalStoreKeys.CHAT_TOKEN);
    localStorage.removeItem(ELocalStoreKeys.PINNED_ID);
    thunkAPI.dispatch(resetChatState());
    return true;
  } catch (error: any) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

const pinMessage = createAsyncThunk(
  EChat.pinMessage,
  async ({ messageId }: IPinnedMessageParams, thunkAPI) => {
    try {
      const message = await CometChat.getMessageDetails(messageId);
      const tags: Array<String> = [EMessageTags.PINNED];
      if (message.getType() === EMessageType.TEXT) {
        (message as CometChat.TextMessage).setTags(tags);
      } else if (message.getType() === EMessageType.IMAGE) {
        (message as CometChat.MediaMessage).setTags(tags);
      }
      const editedMessage = await CometChat.editMessage(message);
      return {
        id: editedMessage.getId().toString(),
        data: JSON.parse(
          JSON.stringify(
            (
              editedMessage as CometChat.TextMessage | CometChat.MediaMessage
            ).getData()
          )
        ),
        chatId: editedMessage.getReceiverId(),
        type: editedMessage.getType(),
      } as IPinnedMessage;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const getPinnedMassages = createAsyncThunk(
  EChat.receivePinnedMessage,
  async (groupId: string, thunkAPI) => {
    try {
      const tags: Array<String> = [EMessageTags.PINNED];
      const messagesRequest = new CometChat.MessagesRequestBuilder()
        .setGUID(groupId)
        .setLimit(1)
        .hideDeletedMessages(true)
        .setTags(tags)
        .withTags(true)
        .build();
      const pinnedMessages = await messagesRequest.fetchPrevious();
      if (pinnedMessages.length) {
        return {
          id: pinnedMessages[0].getId().toString(),
          data: JSON.parse(
            JSON.stringify(
              (
                pinnedMessages[0] as
                  | CometChat.TextMessage
                  | CometChat.MediaMessage
              ).getData()
            )
          ),
          chatId: groupId,
          type: pinnedMessages[0].getType(),
        } as IPinnedMessage;
      }
      return;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const unpinMessage = createAsyncThunk(
  EChat.unpinMessage,
  async ({ messageId }: IPinnedMessageParams, thunkAPI) => {
    try {
      const message = await CometChat.getMessageDetails(messageId);
      const tags: Array<String> = [EMessageTags.UNPINNED];
      if (message.getType() === EMessageType.TEXT) {
        (message as CometChat.TextMessage).setTags(tags);
      } else if (message.getType() === EMessageType.IMAGE) {
        (message as CometChat.MediaMessage).setTags(tags);
      }
      return await CometChat.editMessage(message);
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const updatePinnedMessage = createAsyncThunk(
  EChat.updateMessages,
  async (messageInfo: IUpdatedMessage, thunkAPI) => {
    try {
      if (messageInfo.tags.includes(EMessageTags.PINNED)) {
        thunkAPI.dispatch(
          setPinnedMessage({
            id: messageInfo.id,
            data: messageInfo.data,
            chatId: messageInfo.chatId,
            type: messageInfo.type,
          })
        );
      } else if (messageInfo.tags.includes(EMessageTags.UNPINNED)) {
        const { chat } = thunkAPI.getState() as RootState;
        chat.pinnedMessage &&
          chat.pinnedMessage.id === messageInfo.id &&
          thunkAPI.dispatch(setPinnedMessage(undefined));
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const addOrRemoveReactionToMessage = createAsyncThunk(
  EChat.addReactionToMessage,
  async ({ messageId, emoji }: ILikedMessageParams, thunkAPI) => {
    try {
      await CometChat.callExtension('reactions', 'POST', 'v1/react', {
        msgId: messageId,
        emoji: emoji,
      });
      return { messageId, emoji };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export {
  loginChatUser,
  getUserConversation,
  logoutChatUser,
  getMessages,
  updateMessages,
  sendMessage,
  sendImage,
  deleteMessage,
  getMembers,
  updateUserStatus,
  readMessage,
  updateMessagesRead,
  getPrevMessages,
  pinMessage,
  getPinnedMassages,
  unpinMessage,
  updatePinnedMessage,
  getUnreadMessagesByChat,
  addOrRemoveReactionToMessage,
};
